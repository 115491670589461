import React from "react"
import PropTypes from "prop-types"
import Links from "./Links"

export default class ProjectGroup extends React.Component {
  render() {
    const { links, developer, nodeAddr, devLink } = this.props
    return (
      <>
        <h3>
          {devLink ? (
            <a href={devLink} target="_blank" rel="noreferrer">
              {developer}
            </a>
          ) : (
            <>{developer}</>
          )}
          {nodeAddr && (
            <>
              {" - "}
              <a href={`https://explorer.unification.io/validator/${nodeAddr}`}>Validator</a>
            </>
          )}
        </h3>
        <Links links={links} />
      </>
    )
  }
}

ProjectGroup.propTypes = {
  developer: PropTypes.string,
  devLink: PropTypes.string,
  nodeAddr: PropTypes.string,
  links: PropTypes.array,
}
