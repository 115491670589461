import React from "react"
import { Row, Col } from "react-bootstrap"
import NetworkStatus from "../components/NetworkStatus"
import Links from "../components/Links"

function Home() {
  return (
    <>
      <h1>FUND Network Status</h1>
      <Row>
        <Col>
          <NetworkStatus network="mainnet" title="MainNet" />
          <h3>Current</h3>
          <Links
            links={[
              { title: "Public RPC - SSL", url: "https://rpc1.unification.io:443", desc: "" },
              { title: "Public gRPC - SSL", url: "https://grpc.unification.io", desc: "" },
              {
                title: "Public REST - SSL",
                url: "https://rest.unification.io",
                desc: (
                  <>
                    See{" "}
                    <a href="https://rest.unification.io/swagger/" target="_blank" rel="noreferrer">
                      https://rest.unification.io/swagger/
                    </a>{" "}
                    for endpoints{" "}
                  </>
                ),
              },
              {
                title: "State Sync 1",
                url: "http://sync1.unification.io:26657",
                desc: "RPC for use during state-sync",
              },
              {
                title: "State Sync 2",
                url: "http://sync2.unification.io:26657",
                desc: "RPC for use during state-sync",
              },
              { title: "Block Explorer", url: "https://explorer.unification.io", desc: "" },
            ]}
          />

          <h3>Legacy FUND-Mainchain-MainNet-v1</h3>

          <Links
            links={[
              {
                title: "Legacy REST",
                url: "https://legacy-fund-mainnet-v1-rest.unification.io",
                desc: (
                  <>
                    Archived transactions and blocks for FUND-Mainchain-MainNet-v1, up to and including the
                    final block produced (11,039,060) before the upgrade to FUND-MainNet-2
                    <br />
                    See{" "}
                    <a
                      href="https://legacy-fund-mainnet-v1-rest.unification.io/swagger-ui/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://legacy-fund-mainnet-v1-rest.unification.io/swagger-ui/
                    </a>{" "}
                    for endpoints{" "}
                  </>
                ),
              },
              {
                title: "Legacy Block Explorer",
                url: "https://legacy-fund-mainnet-v1-explorer.unification.io",
                desc: (
                  <>
                    Archived transactions and blocks for FUND-Mainchain-MainNet-v1, up to and including the
                    final block produced (11,039,060) before the upgrade to FUND-MainNet-2
                  </>
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <NetworkStatus network="testnet" title="TestNet" />
          <Links
            links={[
              { title: "Public RPC - SSL", url: "https://rpc-testnet.unification.io:443", desc: "" },
              {
                title: "Public REST - SSL",
                url: "https://rest-testnet.unification.io",
                desc: (
                  <>
                    See{" "}
                    <a href="https://rest-testnet.unification.io/swagger/" target="_blank" rel="noreferrer">
                      https://rest-testnet.unification.io/swagger/
                    </a>{" "}
                    for endpoints{" "}
                  </>
                ),
              },
              {
                title: "State Sync 1",
                url: "http://sync1-testnet.unification.io:26657",
                desc: "RPC for use during state-sync",
              },
              {
                title: "State Sync 2",
                url: "http://sync2-testnet.unification.io:26657",
                desc: "RPC for use during state-sync",
              },
              { title: "Block Explorer", url: "https://explorer-testnet.unification.io", desc: "" },
              { title: "Test FUND Faucet", url: "https://faucet-testnet.unification.io", desc: "" },
            ]}
          />
        </Col>
      </Row>
    </>
  )
}

export default Home
