import React from "react"
import ProjectGroup from "../components/ProjectGroup"

function Community() {
  return (
    <>
      <h1>Community Developed Projects</h1>

      <h4>Projects developed by the community and node operators</h4>

      <ProjectGroup
        developer="Chainmasters"
        nodeAddr="undvaloper16hten3u6gqcu30t2rrt8e8ayn8690he0x9zzs6"
        devLink=""
        links={[
          { title: "State DB snapshots", url: "https://www.unification.chainmasters.net", desc: "" },
          { title: "REST Server", url: "https://rest.unification.chainmasters.net", desc: "" },
          {
            title: "HTTP Gateway",
            url: "https://gitlab.com/chainmasters/unification/unification-http-gateway",
            desc: "",
          },
          {
            title: "XFUND emission simulator Telegram Bot",
            url: "https://t.me/chainmasters_info_bot",
            desc: <>@chainmasters_info_bot - send &quot;/start&quot; for commands</>,
          },
          {
            title: "FUND Block Explorer",
            url: "https://explorer.unification.chainmasters.ninja/unification",
            desc: "",
          },
        ]}
      />

      <ProjectGroup
        developer="FUND-Family"
        nodeAddr="undvaloper18clcgl90cs99yt4zrzxj9dyf0dsd343fklkuzl"
        devLink=""
        links={[
          {
            title: "FUND-Radar",
            url: "https://fund-radar.de",
            desc: "Real-time block explorer",
          },
          {
            title: "REST API",
            url: "http://fund-radar.de:1317/api",
            desc: "",
          },
          {
            title: "Telegram Bot",
            url: "https://t.me/fund_radar",
            desc: "",
          },
        ]}
      />
    </>
  )
}

export default Community
