import React from "react"
import ProjectGroup from "../components/ProjectGroup"

function Foundation() {
  return (
    <>
      <h1>Foundation Developed Projects</h1>

      <h3>
        Projects developed by{" "}
        <a href="https://unification.com" target="_blank" rel="noreferrer">
          Unification Foundation
        </a>
      </h3>

      <h4>Network</h4>

      <ProjectGroup
        developer=""
        nodeAddr=""
        devLink=""
        links={[
          {
            title: "Mainchain",
            url: "https://github.com/unification-com/mainchain",
            desc: "Node Server and Client software that runs the FUND network",
          },
          {
            title: "Documentation",
            url: "https://docs.unification.io",
            desc: "Everything you need to know about setting up a node and joining as a validator",
          },
        ]}
      />

      <h4>Wallets and Explorers</h4>

      <ProjectGroup
        developer=""
        nodeAddr=""
        devLink=""
        links={[
          {
            title: "Web Wallet - Chrome Extension",
            url: "https://chrome.google.com/webstore/detail/mkjjflkhdddfjhonakofipfojoepfndk",
            desc: "Official Wallet for interacting with the FUND Network",
          },
          {
            title: "Web Wallet - Github",
            url: "https://github.com/unification-com/web-wallet",
            desc: "Official Wallet for interacting with the FUND Network (source code)",
          },
          {
            title: "Block Explorer",
            url: "https://github.com/unification-com/mainchain-explorer",
            desc: (
              <>
                The FUND Block{" "}
                <a href="https://explorer.unification.io" target="_blank" rel="noreferrer">
                  explorer
                </a>{" "}
                - a BigDipper fork
              </>
            ),
          },
          {
            title: "UND-JS SDK",
            url: "https://github.com/unification-com/und-js",
            desc: (
              <>
                A Javascript SDK for interacting with FUND REST endpoints - can be installed as a{" "}
                <a
                  href="https://www.npmjs.com/package/@unification-com/und-js"
                  target="_blank"
                  rel="noreferrer"
                >
                  NodeJS package
                </a>
              </>
            ),
          },
        ]}
      />

      <h4>xFUND</h4>

      <ProjectGroup
        developer=""
        nodeAddr=""
        devLink=""
        links={[
          {
            title: "xFUND Smart Contract",
            url: "https://github.com/unification-com/xfund-smart-contract",
            desc: (
              <>
                xFUND ERC20 smart contract. Deployed{" "}
                <a
                  href="https://etherscan.io/address/0x892A6f9dF0147e5f079b0993F486F9acA3c87881#code"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </>
            ),
          },
          {
            title: "xFUND Portal",
            url: "https://xfund.unification.io",
            desc: "Portal for emissions data and xFUND claims",
          },
          {
            title: "Yield Farm",
            url: "https://yield.unification.io/",
            desc: "",
          },
          {
            title: "xFUND Router/OoO",
            url: "https://github.com/unification-com/xfund-router",
            desc:
              "OoO allows developers to include Crypto price data in their smart " +
              "contracts, while the xfund-router routes xFUND payments for the data " +
              "and allows data requests and fulfillment.",
          },
          {
            title: "VOR",
            url: "https://github.com/unification-com/xfund-vor",
            desc: "Verified Open Randomness - a robust method to include randomness in your smart contracts",
          },
        ]}
      />

      <h4>WRKChains & BEACONs</h4>

      <ProjectGroup
        developer=""
        nodeAddr=""
        devLink=""
        links={[
          {
            title: "Finchains",
            url: "https://crypto.finchains.io",
            desc:
              "WRKChain that gathers data from multiple exchanges and monitors for price " +
              "discrepancies. Data is stored on-chain and serves as the basis for OoO data.",
          },
          {
            title: "Finchains - OoO Docs",
            url: "https://docs.finchains.io",
            desc:
              "Documentation for integrating the xfund-router smart " +
              "contract library, and requesting data from the OoO API",
          },
          {
            title: "Finchains - Github",
            url: "https://github.com/unification-com/finchains-v2",
            desc: "",
          },
          {
            title: "Public Accountability Chain",
            url: "https://pac.foundation",
            desc: "",
          },
          {
            title: "PAC - Github",
            url: "https://github.com/unification-com/pac",
            desc: "",
          },
        ]}
      />
    </>
  )
}

export default Foundation
