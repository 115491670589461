import React from "react"
import { Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

function SidebarNav() {
  return (
    <Nav variant="pills" className="flex-column" defaultActiveKey="home">
      <Nav.Item href="/">
        <Nav.Link as={Link} to="/" className="nav-link" eventKey="home">
          Network Status
        </Nav.Link>
      </Nav.Item>
      <Nav.Item href="/foundation">
        <Nav.Link as={Link} to="/foundation" className="nav-link" eventKey="foundation">
          Foundation Projects
        </Nav.Link>
      </Nav.Item>
      <Nav.Item href="/community">
        <Nav.Link as={Link} to="/community" className="nav-link" eventKey="community">
          Community Projects
        </Nav.Link>
      </Nav.Item>
      <Nav.Item href="/community">
        <Nav.Link as={Link} to="/xfund_emulator" className="nav-link" eventKey="xfund_emulator">
          xFUND Emulator
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export default SidebarNav
