const { bech32 } = require("bech32")

const getDelegatorAddress = (operatorAddr) => {
  const address = bech32.decode(operatorAddr)
  return bech32.encode("und", address.words)
}
const getTotal = (data) => {
  let total = 0
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const num = Number(data[key])
      if (num > 0) {
        total += num
      }
    }
  })
  return total
}

const normaliseProportionally = (data) => {
  const normalisedData = {}
  const total = getTotal(data)
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const num = Number(data[key])
      normalisedData[key] = num / total
    }
  })
  return normalisedData
}

const getEntries = (data, entryMultiplier, defaultForZero) => {
  const entries = {}
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const num = Number(data[key])
      let e = num * entryMultiplier
      if (e < 1) {
        e = defaultForZero
      }
      entries[key] = Math.round(e)
    }
  })
  return entries
}

const assignEntries = (data) => {
  const entries = []
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const num = Number(data[key])
      for (let i = 0; i < num; i += 1) {
        entries.push(key)
      }
    }
  })
  return entries
}

const shuffleTickets = (tickets) => {
  const ticketsShuffle = [...tickets]
  let currentIndex = ticketsShuffle.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = ticketsShuffle[currentIndex]
    ticketsShuffle[currentIndex] = ticketsShuffle[randomIndex]
    ticketsShuffle[randomIndex] = temporaryValue
  }

  return ticketsShuffle
}

const getRandomEmission = (tickets) => {
  return Math.round(Math.random() * (tickets.length - 1))
}

export {
  getDelegatorAddress,
  getTotal,
  normaliseProportionally,
  getEntries,
  assignEntries,
  shuffleTickets,
  getRandomEmission,
}
