import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Switch, Route } from "react-router-dom"
import Community from "../pages/Community"
import Foundation from "../pages/Foundation"
import XfundEmulator from "../pages/XfundEmulator"
import Home from "../pages/Home"
import Header from "../components/Header"
import SidebarNav from "../components/SidebarNav"

function Layout() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <SidebarNav />
        </Col>
        <Col sm={10}>
          <Switch>
            <Route exact path="/" component={Home} eventKey="home" />
            <Route exact path="/community" component={Community} eventKey="community" />
            <Route exact path="/foundation" component={Foundation} eventKey="community" />
            <Route exact path="/xfund_emulator" component={XfundEmulator} eventKey="xfund_emulator" />
            <Route
              render={function notFound() {
                return <p>Not found</p>
              }}
            />
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

export default Layout
