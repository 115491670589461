import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

function Header() {
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <img
          src="/assets/img/unification_logowhite.png"
          height="40"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="https://docs.unification.io/mainchain" target="_blank">
          FUND Docs
        </Nav.Link>
        <Nav.Link href="https://docs.unification.io/ooo" target="_blank">
          OoO Docs
        </Nav.Link>
        <Nav.Link href="https://docs.unification.io/vor" target="_blank">
          VOR Docs
        </Nav.Link>
        <Nav.Link href="https://xfund.unification.io" target="_blank">
          xFUND
        </Nav.Link>
        <Nav.Link href="https://github.com/unification-com" target="_blank">
          Github
        </Nav.Link>
        <Nav.Link href="https://discord.gg/SeB69w5" target="_blank">
          Discord
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default Header
