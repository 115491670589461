import React from "react"
import PropTypes from "prop-types"
import ListGroup from "react-bootstrap/ListGroup"

export default class Links extends React.Component {
  render() {
    const { links } = this.props

    const linkItems = links.map((link) => (
      <ListGroup.Item key={link.url}>
        {link.desc ? (
          <>
            <strong>{link.title}</strong>
            {": "}
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.url}
            </a>
            <br />
            {link.desc}
          </>
        ) : (
          <>
            <strong>{link.title}</strong>
            {": "}
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.url}
            </a>
          </>
        )}
      </ListGroup.Item>
    ))

    return (
      <>
        <ListGroup>{linkItems}</ListGroup>
      </>
    )
  }
}

Links.propTypes = {
  links: PropTypes.array,
}
