import React from "react"
import { Row, Col } from "react-bootstrap"
import XfundEmulatorContainer from "../components/XfundEmulatorContainer"

function xFundEmulator() {
  return (
    <>
      <h1>xFUND Emissions Algorithm Simulator</h1>
      <Row>
        <Col>
          <XfundEmulatorContainer />
        </Col>
      </Row>
    </>
  )
}

export default xFundEmulator
