import React from "react"
import fetch from "isomorphic-unfetch"
import PropTypes from "prop-types"
import Table from "react-bootstrap/Table"

export default class NetworkStatus extends React.Component {
  constructor(props) {
    super(props)

    this.fetchData = this.fetchData.bind(this)

    this.state = {
      totalSupplyFormatted: "",
      numValidators: "",
      height: "",
      chainId: "",
      dataLoading: false,
    }
  }

  async fetchData() {
    const { network } = this.props

    let resData = {}
    let totalSupplyFormatted
    let numValidators
    let height
    let chainId

    const p = 10 ** 9
    await this.setState({ dataLoading: true })
    const dataRes = await fetch("/data/data.json")

    if (dataRes.ok && dataRes.status === 200) {
      resData = await dataRes.json()

      const netData = resData[network]
      const nundMn = parseInt(netData.totalSupply, 10)
      numValidators = netData.validators.validators.length
      height = new Intl.NumberFormat("en-GB", {}).format(netData.validators.block_height)
      const totalSupply = nundMn / p
      totalSupplyFormatted = new Intl.NumberFormat("en-GB", {}).format(totalSupply)
      chainId = netData.status.node_info.network
    }
    await this.setState({
      numValidators,
      height,
      totalSupplyFormatted,
      chainId,
      dataLoading: false,
    })
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { numValidators, height, totalSupplyFormatted, chainId } = this.state
    const { title } = this.props

    return (
      <>
        <h2>{title}</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Chain ID</th>
              <th>Active Validators</th>
              <th>Block</th>
              <th>Circulating Supply</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{chainId}</td>
              <td>{numValidators}</td>
              <td>{height}</td>
              <td>{totalSupplyFormatted} FUND</td>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }
}

NetworkStatus.propTypes = {
  network: PropTypes.string,
  title: PropTypes.string,
}
