import React from "react"
import fetch from "isomorphic-unfetch"
import Table from "react-bootstrap/Table"
import { Row, Col, Button } from "react-bootstrap"
import _ from "lodash"

const {
  getDelegatorAddress,
  getTotal,
  normaliseProportionally,
  getEntries,
  assignEntries,
  shuffleTickets,
  getRandomEmission,
} = require("../utils/emulator")

export default class XfundEmulatorContainer extends React.Component {
  constructor(props) {
    super(props)

    this.resetSimulation = this.resetSimulation.bind(this)
    this.getValidators = this.getValidators.bind(this)
    this.loadData = this.loadData.bind(this)
    this.generateEmissions = this.generateEmissions.bind(this)

    this.state = {
      ENTRY_MULTIPLIER: 10000,
      DEFAULT_FOR_ZERO: 1,
      NUM_EMISSIONS: 24,
      numRuns: 0,
      totalStake: 0,
      totalProportion: 0,
      totalEntries: 0,
      totalProb: 0.0,
      emissions: [],
      allocations: [],
      accumulated: {},
      valset: [],
      emLogs: {},
      dataLoading: true,
    }
  }

  async getValidators() {
    const url = new URL("https://rest.unification.io/staking/validators?limit=100")
    const valset = []

    const response = await fetch(url)

    const lcdValset = await response.json()

    for (let i = 0; i < lcdValset.result.length; i += 1) {
      const valData = {}
      const val = lcdValset.result[i]

      if (!val.jailed) {
        valData.moniker = val.description.moniker
        valData.operatorAddress = val.operator_address
        valData.consensusPubkey = val.consensus_pubkey
        valData.selfDelegateAddress = getDelegatorAddress(val.operator_address)
        valData.status = val.status
        valData.shares = val.delegator_shares
        valData.tokens = val.tokens

        valset.push(valData)
      }
    }

    await this.setState({ valset, dataLoading: false })

    return valset
  }

  loadData() {
    const { valset } = this.state
    const vals = {}

    for (let i = 0; i < valset.length; i += 1) {
      const v = valset[i]
      vals[v.moniker] = v.tokens
    }

    return vals
  }

  async componentDidMount() {
    await this.getValidators()
    await this.generateEmissions()
  }

  async resetSimulation() {
    await this.setState({
      numRuns: 0,
      totalStake: 0,
      totalProportion: 0,
      totalEntries: 0,
      totalProb: 0.0,
      emissions: [],
      allocations: [],
      accumulated: {},
      emLogs: {},
    })
    await this.generateEmissions()
  }

  async generateEmissions() {
    const emLogs = {}
    const dateNow = new Date()
    const emissions = []

    const { NUM_EMISSIONS, accumulated, ENTRY_MULTIPLIER, DEFAULT_FOR_ZERO, numRuns } = this.state
    const accumulatedTmp = accumulated

    // log
    emLogs.emission_date = dateNow

    // log
    emLogs.num_emissions = NUM_EMISSIONS

    const entrants = this.loadData()

    // log
    emLogs.entrants = entrants

    let norm
    let entries
    let emissionTickets
    let emissionTicketsShuffled
    let totalActiveStakes
    const allocations = []

    if (Object.keys(entrants).length > 0) {
      totalActiveStakes = getTotal(entrants)
      norm = normaliseProportionally(entrants)
      entries = getEntries(norm, ENTRY_MULTIPLIER, DEFAULT_FOR_ZERO)
      emissionTickets = assignEntries(entries)
      emissionTicketsShuffled = shuffleTickets(emissionTickets)
      // log
      emLogs.total_active_stakes = totalActiveStakes
      emLogs.norm = norm
      emLogs.entries = entries
      emLogs.emission_tickets = emissionTickets
      emLogs.emission_tickets_shuffled = emissionTicketsShuffled
      emLogs.allocations = []

      for (let i = 1; i <= NUM_EMISSIONS; i += 1) {
        const randNumber = getRandomEmission(emissionTicketsShuffled)
        const validatorMoniker = emissionTicketsShuffled[randNumber]

        const alloc = {
          em: i,
          arr_idx: randNumber,
          val: validatorMoniker,
        }

        if (validatorMoniker in accumulatedTmp) {
          accumulatedTmp[validatorMoniker] += 1
        } else {
          accumulatedTmp[validatorMoniker] = 1
        }

        allocations.push(alloc)
      }

      emLogs.allocations = allocations
    }

    let totalStake = 0
    let totalProportion = 0
    let totalEntries = 0
    let totalProb = 0
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in entries) {
      totalEntries += entries[property]
    }

    const formatter = new Intl.NumberFormat("en-US")

    Object.keys(entries).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(entries, key)) {
        const stake = formatter.format(entrants[key])
        totalStake += Number(entrants[key])
        const normalised = norm[key]
        totalProportion += normalised
        const numEntries = entries[key]
        const probability = (numEntries / totalEntries) * 100
        totalProb += probability

        const em = {
          key,
          stake,
          normalised,
          numEntries,
          probability: probability.toFixed(2),
        }

        emissions.push(em)
      }
    })

    // const accumulatedSorted = _(accumulatedTmp).toPairs().orderBy([1], ["desc"]).fromPairs().value()

    await this.setState({
      totalStake,
      totalProportion,
      totalEntries,
      totalProb,
      emissions: _.sortBy(emissions, "numEntries"),
      allocations,
      emLogs,
      numRuns: numRuns + 1,
      // accumulated: accumulatedSorted,
    })
  }

  render() {
    const {
      ENTRY_MULTIPLIER,
      DEFAULT_FOR_ZERO,
      // NUM_EMISSIONS,
      totalStake,
      totalEntries,
      totalProportion,
      totalProb,
      emissions,
      allocations,
      emLogs,
      // accumulated,
      // numRuns,
    } = this.state

    const formatter = new Intl.NumberFormat("en-US")

    return (
      <>
        <p>
          Simulator source code available on{" "}
          <a
            href="https://github.com/unification-com/xfund-emissions-simulator"
            target="_blank"
            rel="noreferrer"
          >
            GitHub
          </a>
        </p>

        <h2>1. Calculating Allocation Entries</h2>

        <p>
          At the point of running, the validator set is acquired from{" "}
          <a href="https://rest.unification.io/staking/validators?limit=100" target="_blank" rel="noreferrer">
            https://rest.unification.io/staking/validators?limit=100
          </a>
          . For the purposes of this simulation, it is refreshed when the page is reloaded, not when the
          &quot;Run Simulation&quot; button is clicked.
        </p>

        <p>
          The Validators&apos; stake (self-delegated and delegated) is used to calculate their proportion of
          the total ACTIVE network stake as a %. Delegations to inactive or jailed nodes do not count, and
          jailed/inactive nodes are not included in the allocations.
        </p>

        <p>
          The proportional value is multiplied by <strong>{ENTRY_MULTIPLIER}</strong> and rounded to give the
          &quot;number of entries&quot;, with any validators resulting in zero, defaulting to
          <strong>{DEFAULT_FOR_ZERO}</strong> entry. The number of entries are for each of the 24 draws, with
          each draw being mutually exclusive of one another (i.e. a validator has the same chance of being
          allocated xFUND in draw #4 as they do draw #19).
        </p>

        <Table size="sm" striped bordered>
          <thead>
            <tr>
              <th>Validator</th>
              <th>Token Stake</th>
              <th>Stake Proportion</th>
              <th># Entries</th>
              <th>Allocation Probability</th>
            </tr>
          </thead>
          <tbody>
            {emissions.map(({ key, stake, normalised, numEntries, probability }) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{stake}</td>
                <td>{normalised}</td>
                <td>{numEntries}</td>
                <td>{probability}%</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td>
                <strong>{formatter.format(totalStake)}</strong>
              </td>
              <td>
                <strong>{Math.round(totalProportion)}</strong>
              </td>
              <td>
                <strong>{totalEntries}</strong>
              </td>
              <td>
                <strong>{totalProb.toFixed(2)}%</strong>
              </td>
            </tr>
          </tbody>
        </Table>

        <h2>2. Running the Emissions</h2>

        <p>
          An array containing <strong>{totalEntries}</strong> entries is generated, with each validitor being
          allocated their number of entries as per the table above.
        </p>

        <p>
          The entries array is shuffled to randomise the ordering, and for each of the 24 emissions, a random
          number between <strong>0 and {totalEntries - 1}</strong> is generated. This random number is used as
          the array ID to select which validator the emission is allocated to.
        </p>
        <p>
          <Button variant="primary" onClick={this.generateEmissions}>
            Run Simulation
          </Button>
        </p>
        <Row>
          <Col>
            <h2>3. Emission Allocations</h2>
            <Table size="sm" striped bordered>
              <thead>
                <tr>
                  <th>Emission #</th>
                  <th>Array Idx</th>
                  <th>Allocated To</th>
                </tr>
              </thead>
              <tbody>
                {allocations.map(({ em, arr_idx, val }) => (
                  <tr key={em}>
                    <td>{em}</td>
                    <td>{arr_idx}</td>
                    <td>{val}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <h2>Emission Logs</h2>
            <p>
              <textarea rows="24" cols="50" readOnly value={JSON.stringify(emLogs, null, 2)} />
            </p>
            {/* <Table size="sm" striped bordered> */}
            {/*  <thead> */}
            {/*    <tr> */}
            {/*      <th>Validator</th> */}
            {/*      <th>xFUND</th> */}
            {/*    </tr> */}
            {/*  </thead> */}
            {/*  <tbody> */}
            {/*    {Object.keys(accumulated).map((keyName) => ( */}
            {/*      <tr key={keyName}> */}
            {/*        <td>{keyName}</td> */}
            {/*        <td>{accumulated[keyName]}</td> */}
            {/*      </tr> */}
            {/*    ))} */}
            {/*  </tbody> */}
            {/* </Table> */}
          </Col>
        </Row>
      </>
    )
  }
}
